import React from 'react'
import Payment from "../../components/paymentModule/Payment"

function Welcome() {
  return (
    <>
      <Payment />

    </>
  )
}

export default Welcome